
@import "../../../../less/_mixins.less";
.hot-deals-carousel {
	background-color: tint(@CL, 90%);
	position: relative;

	h2 {
		font-weight: 400;
		color: shade(@CL, 40%);
		size: 16px;
		text-align: center;
		text-transform: uppercase;

		.mobile-styles({
			font-size: 14px;
		});
	}

	.tablet-styles({
		button {
			display: flex;
		}
	})
}

.hot-deals-viewport {
	.list-slider(3);
	overflow-x: hidden;
	margin: 0 40px;

	.tablet-styles({
		.list-slider(2);
	});

	.mobile-styles({
		margin: 0 35px;
		.list-slider(1);
	});
}
