
@import "../../../less/_mixins.less";
.carousel-page-button {
	.button-reset();
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background: tint(@LB, 90%);
	border: 2px solid tint(@LB, 60%);
	color: @DB;
	font-size: 1.5rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 2;

	&.previous {
		left: -0;
		border-radius: 0.4rem 0 0 0.4rem;
		padding: 1.5rem 0.25rem 1.5rem 0.4rem;
	}
	&.next {
		right: 0;
		border-radius: 0 0.4rem 0.4rem 0;
		padding: 1.5rem 0.4rem 1.5rem 0.25rem;
	}

	&[aria-disabled="true"] {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&.hide-tablet {
		.tablet-styles({
			display: none;
		});
	}

	&.hide-mobile {
		.mobile-styles({
			display: none;
		});
	}

	.mobile-styles({
		font-size: 1rem;

		&.previous, &.next {
			padding: 1rem 0.25rem;
		}
	})
}
