
@import "../../../../less/_mixins.less";
a.hot-deal-wrapper {
	color: inherit;
	text-decoration: none;
	margin: 0 auto;
	padding: 0 10px;
	max-width: 440px;
	width: 100%;
	&:visited, &:focus,
	&:hover, &:active {
		color: inherit;
		text-decoration: none;
	}
}
.hot-deal {
	background-color: #FFF;
	border-radius: 6px;
	cursor: pointer;
	margin: 0 auto;
	width: 36em;
	overflow: hidden;
	padding: 1em 2em 1.6em;

	&:hover {
		.hd-border {
			height: 6px;
		}
	}

	.ms-styles({
		padding: 10px;
	});

}

.hd-border {
	background-color: tint(@CL, 60%);
	bottom: 0;
	left: 0; right: 0;
	height: 3px;
	transition: height 0.4s;
}

.hd-img {
	flex: 0 0 13em;
	margin-right: 1em;
	margin-bottom: 1em;
	img {
		height: 13em;
		width: 13em;
	}
}

.hd-headline {
	font-weight: 400;
	font-size: 1.4em;
	margin-bottom: 0.2em;
	text-transform: uppercase;

	.icon {
		margin-right: 3px;
		font-size: 1.3em;
	}
}

.hd-lg-text {
	font-family: 'Roboto Condensed', sans-serif;
	font-weight: bold;
	line-height: 0px;
	margin-top: 0.1em;

	sup {
		font-size: smaller;
		vertical-align: super;
	}

	.hd-prefix {
		font-family: 'Roboto';
		font-size: 0.3em;
		font-weight: normal;
		position: relative;
		top: -0.3em;
	}
	.hd-suffix {
		font-size: 0.56em;
		position: relative;
		top: 0.1em;
	}
	.hd-text {
		line-height: .8em;
		position: relative;
		&.custom {
			line-height: 1.1em;
			margin-top: 0;
		}
	}
	.hd-off {
		font-size: 0.45em;
		position: absolute;
		right: -1.6em;
		top: 0.4em;
		line-height: 0.9em;
	}
}

.hd-lg {
	font-weight: bold;
	line-height: 1em;
	margin-bottom: 4px;
	margin-top: 3px;
}

.hd-promo {
	color: #b12f31;
	font-weight: bold;
	font-size: 1.6em;
	text-transform: uppercase;
	margin-top: 0.4em;
}

.hd-small {
	color: shade(@CL, 40);
	font-size: 1.3em;
	margin-top: 0.5em;
	span {
		text-decoration: line-through;
	}
}

.hd-member-price,
.hd-description {
	display: block;
	font-size: 1.4em;
	font-weight: 500;
	line-height: 1.5em;
	max-width: 90%;
	margin: 0 auto;
	text-align: left;
}

.hd-member-price {
	color: #0054A4;
	text-transform: uppercase;
}
