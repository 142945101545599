@import '_variables.less';
@import '_mixins.less';
@import 'lightslider.less';

/*=======================================================================
== Homepage Components
========================================================================*/

.home-images {
	img {
		width: 100%;
	}
	.img-full {
		display: block;
		margin-right: 25px;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		&:last-child {
			margin-right: 0;
		}
		.mobile-styles({
			margin: 0 0 15px;
			width: 100%;
			&:last-child {
				margin-bottom: 0;
			}
		})
	}
	.img-mobile {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.home-image .img-full {
	width: 100%;
	img {
		width: 100%;
	}
}

.home-carousel {
	overflow: hidden;
	.home-carousel-track {
		display: flex;
		flex-wrap: nowrap;
		> li {
			flex: 0 0 100%;
		}
	}
}

button.home-carousel-btn {
	appearance: none;
	background-color: transparent;
    background-image: url(/assets/images/slider/controls1.svg);
    background-repeat: no-repeat;
	border: none;
    cursor: pointer;
	width: 60px;
	right: 0;
    top: 50%;
    height: 60px;
    position: absolute;
    z-index: 2;
	transform: translateY(-50%);

	&:first-child {
		left: 0;
		right: auto;
		transform: translateY(-50%) scaleX(-1);
	}

	.tablet-styles({
		display: none;
	});
}

.hero-slide a {
	display: block;
	img {
		.absolute();
		object-fit: contain
	}
}

.hero-slide-full {
	.aspect-ratio(1440, 350);
}

.hero-slide-mobile {
	.aspect-ratio(767, 350);
}

#home {
	h2 {
		margin-bottom: 25px;
		text-align: center;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: #666;
	}
    img{
        max-width:100%;
    }
	.home-content-block {
		margin-bottom: 25px;
	}
	.footer-images {
		margin: 20px auto;
		width: 100%;
		justify-content: center;
		.image-wrapper {
			margin: 20px auto;
		}
		.image {
			width: 450px;
			min-width: 400px;
			.mobile-styles({
				min-width: 320px;
			});
		}

		.forbes-image {
			display: inline-block;
			margin: 0 10px;
			width: 300px;
		}
	}
}

.home-countdown {
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	padding: 12px;
	margin-bottom: 25px;
	margin-top: -28px;
	min-height: 56px;
	font-family: 'Roboto', arial, sans-serif;

	.label {
		text-transform: uppercase;
	}

	.countdown-message {
		font-weight: lighter;
	}

	.countdown-divider {
		margin: 0 10px;
		font-size: 14px;
	}

	.countdown {
		font-family: Roboto;
		.mobile-styles( {
			margin-top: 10px;
		}

		);
	}

	.countdown-link {
		text-decoration: none;
		color: inherit;
	}

	.link-text {
		text-decoration: underline;
		font-size: 14px;
		font-weight: lighter;
		opacity: .9;

		.mobile-styles({
			margin-top: 10px;
		});
	}

	.mobile-styles({
		font-size: 16px;
		min-height: 50px;
	});
}

.home-text-block {
	text-align: center;
	padding: 5px;
	margin-bottom: 25px;
	line-height: normal;

	&.home-text-size-small {
		font-size: 14px;
		.mobile-styles({
			font-size: 12px;
		});
	}

	&.home-text-size-medium {
		font-size: 16px;
		.mobile-styles({
			font-size: 14px;
		});
	}

	&.home-text-size-large {
		font-size: 20px;
		.mobile-styles({
			font-size: 16px;
		});
	}
}

#home .home-hot-deals {
	background-color: #F2F3F8;
	height: 307px;
	padding-top: 15px;

	h2 {
		color: #4C5271;
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 10px;

		.mobile-styles({
			font-size: 16px;
		});
	}

	.hot-deals-carousel {
		max-width: 1300px;
		margin: 0 auto;
	}

	.tablet-styles({
		height: 332px;
		padding: 15px 15px 0;
	});

	.styles-at(489px, {
		height: 240px;
	});
}

#scrollDeptNav {
	padding: 15px 0;

	.container {
		padding: 0;
		padding-left: 15px;
	}

	nav {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		gap: 12px;
		padding: 10px 0;
		/*-ms-overflow-style: none;*/ /* IE and Edge */
		/*scrollbar-width: none;*/ /* Firefox */
		/*&::-webkit-scrollbar {
			display: none;
		}*/
	}

	.dept-scroll {
		border: 1px solid tint(@CL, 60%);
		border-radius: 8px;
		padding: 8px;
		text-decoration: none;
		text-align: center;
		width: 130px;

		&:hover {
			border: 3px solid #086CAC;
			padding: 6px;
		}

		img {
			width: 116px;
			height: 116px;
			max-width: none !important;
		}

		h3 {
			font: normal normal 500 12px/28px Roboto;
			color: #086CAC;
			white-space: nowrap;
			margin: 0;
		}
	}

	.tablet-styles({
		margin: 10px 0;

		.container {
			padding: 0;
		}

		nav {
			gap: 0px;
			padding-right: 12px;
		}

		.dept-scroll {
			display: flex;
			padding: 4px;
			min-width: 146px;
			text-align: left;
			align-items: center;
			gap: 8px;
			margin-left: 12px;

			&:hover {
				border: 1px solid tint(@CL, 60%);
				padding: 4px;
			}

			&:hover:focus-within {
				border: 3px solid #086CAC;
				padding: 2px;
			}

			img {
				width: 50px;
				height: 50px;
			}

			h3 {
				white-space: normal;
				line-height: 14px;
			}
		}
	});
}

// TODO: Is this needed anymore? BestPricePromiseFooter doesn't appear to be used.
// .bpp-wrapper {
// 	max-width: 450px;
// 	margin: 15px auto;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	text-decoration: none;
// 	line-height: normal;
// 	.mobile-styles( {
// 		max-width: 320px;
// 		width: 320px;
// 	}
// 	);
// 	span.img {
// 		padding: 0 15px;
// 	}
// 	img {
// 		width: 100px;
// 		height: 118px;
// 		max-width: none !important;
// 		.mobile-styles( {
// 			width: 80px;
// 			height: 95px;
// 		}
// 		);
// 	}
// 	span.text {
// 		padding: 0 15px 0 0;
// 		text-align: center;
// 	}
// 	span.maintext {
// 		display: block;
// 		font-size: 28px;
// 		font-weight: 700;
// 		margin-bottom: 5px;
// 		.mobile-styles( {
// 			font-size: 18px;
// 		}
// 		);
// 	}
// 	span.subtext {
// 		font-size: 12px;
// 		font-style: italic;
// 		line-height: normal;
// 		br {
// 			display: none;
// 			.mobile-styles( {
// 				display: initial;
// 			});
// 		}
// 	}
// }